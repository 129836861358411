import { render, staticRenderFns } from "./grid-simple-table.vue?vue&type=template&id=916a9270&scoped=true&"
import script from "./grid-simple-table.ts?vue&type=script&lang=ts&"
export * from "./grid-simple-table.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "916a9270",
  null
  
)

export default component.exports